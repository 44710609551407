import React from "react"
import { Container } from "@material-ui/core"
import Footer from "../components/footer/Footer"
import Nav from "../components/navigation/Nav"
import PageBanner from "../components/PageBanner"
import SEO from "../components/SEO"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp"
import Typography from "@material-ui/core/Typography"
import { styled } from "@material-ui/core/styles"

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}))

const CosplayPage = ({ location }) => {
  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <SEO
        title="Cosplay Contest"
        description="Kumite in Tennessee 2021 cosplay contest"
        keywords="KIT, KITX, summer bash, game galaxy, tennessee, texas, fgc, tournament, fighing games, esports, rules"
      />
      <header>
        <Nav pathname={location.pathname} />
        <PageBanner title="Cosplay Contest" />
      </header>
      <main style={{ background: "#d4d4d4", width: "100%", padding: "60px 0" }}>
        <Container maxWidth="lg">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Cosplay Contest Rules &amp; Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    All Cosplays entered in KIT Cosplay Competition MUST be
                    Fighting Game Characters
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Contestants may fill out judge form ahead of time or in
                    person Friday &amp; Saturday of KIT
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        Competitors may fill out the form ahead of time{" "}
                        <a href="https://forms.gle/vMKrzcB3X4in3KBD7">here</a>.
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>
                    Cosplay categories for prizing will be 1st Place (Best
                    Overall), 2nd Place, 3rd Place, and Festive Spirit Award.
                  </Typography>
                </li>
                <li>
                  <Typography>No nudity or profanity on costumes.</Typography>
                </li>
                <li>
                  <Typography>
                    All costume weapons and props must conform to federal, state
                    and local law. All prop, toy, or fake firearms must be
                    clearly marked with a brightly colored safety cap on the
                    barrel.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Real Weapons of any kind are NOT permitted and will be
                    subject to security of the event. This includes but are not
                    limited to:
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        Metal blades whether sharp or blunt e.g. swords, axes,
                        and knives.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Actual guns, rifles, or artillery.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Heavy bats, paddles or clubs including wooden or metal
                        baseball bats.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Bokken - a type of hard wooden (or toughened plastic)
                        training sword.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Explosives and incendiary devices.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Functional strung bows or crossbows, sharp arrows or
                        bolts.
                      </Typography>
                    </li>
                    <li>
                      <Typography>Heavy gas canisters</Typography>
                    </li>
                    <li>
                      <Typography>
                        Protruding nails or other sharp items.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Laser pointers regardless of whether or not they are
                        part of a costume or prop.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Any other items restricted or prohibited from being
                        carried in public by the relevant country’s law.
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>
                    Players with Prop Weapons must be made of sensible materials
                    for cosplay such as foam. If you have any questions about a
                    prop for your cosplay please email the director of events
                    directly with your description and any photos you have:
                    Kumitecosplay@gmail.com
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Costume Contest &amp; Judging</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    All contestants will be required to appear in their cosplay
                    to be pre-judged between the hours of 12 - 2 PM on Sunday,
                    October 31st, 2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    If you do not show up during this designated time you will
                    be disqualified.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Contestants are encouraged to stay in costume on this day as
                    they will all have a few seconds to each walk on the stage
                    and show off their costume to the audience at KIT and on
                    Stream right before the winners are announced.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    The On-Stage portion of the contest and winners ceremony
                    will happen at 2 PM on Sunday, October 31st, 2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Judges reserve the right to ask contestants questions about
                    their cosplay to analyze not only their creation process,
                    but the materials used, details, and other information they
                    may need to make their decisions.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Judges Breakdown</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>1st Place</Typography>
                  <ul>
                    <li>
                      <Typography>
                        Costume must be at least 80% crafted by the entrant.
                        Judges will decide based on craftsmanship, creativity,
                        costuming experience, and stage presentation.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Prizes include $250, Medal, and a KIT Swag Bag.
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>2nd Place</Typography>
                  <ul>
                    <li>
                      <Typography>
                        Costume must be at least 50% crafted by the entrant.
                        Judges will decide based on craftsmanship, creativity,
                        costuming experience, and stage presentation.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Prizes include $150, Medal, and a KIT Swag Bag.
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>3rd Place</Typography>
                  <ul>
                    <li>
                      <Typography>
                        Costume must be at least 25% crafted by the entrant.
                        Judges will decide based on craftsmanship, creativity,
                        costuming experience, and stage presentation.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Prizes include $50, Medal, and a KIT Swag Bag.
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>Festive Spirit Award</Typography>
                  <ul>
                    <li>
                      <Typography>
                        Since KIT is happening on Halloween and many will want
                        to dress up regardless, we’re reserving a special award
                        for those who may not be the most crafty who are still
                        in the spirit of Halloween or just looking to try out
                        cosplay in general. This award will go to whomever
                        demonstrates great stage presentation and enthusiasm as
                        the character they dress as regardless of how much they
                        crafted.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Prizes include a Medal and a KIT Swag Bag.
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </Container>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default CosplayPage
